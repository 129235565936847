.left-sider .ant-layout {
    height: 100vh;
}

body {
    font-family: 'Poppins' !important;
}

h3,
h4,
p {
    margin-bottom: 0px !important;
}

.static-card {
    background: white;
    padding: 25px 5px;
}

.small-cards .ant-card-body {
    padding: 0px;
}

.gallery-image {
    height: 280px;
    border-radius: 10px;
}

.project-sider .ant-menu-title-content a {
    color: white !important;
}

.project-sider .ant-menu-submenu {
    color: white !important;
}

.custom-shape-divider-bottom-1671313053 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1671313053 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 166px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1671313053 .shape-fill {
    fill: #001529;
}

.login-page,
.register-page {
    background: #f0f2f5;
    height: 100vh;
}

.login-form,
.register-form {
    border: 1px solid lightgray;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 6px 1px lightgrey;
    position: absolute;
    top: 50%;
    padding: 50px;
    width: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-form .login-button,
.register-form .register-button {
    background-color: #001529;
    color: white;
    border-color: #001529;
}

.login-form .login-button:hover,
.register-form .register-button:hover {
    background-color: #001529ca;
    color: white;
    border-color: #001529ca;
}

.hero-section {
    background-image: url('../src/assets/home-bg.jpg');
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.footer-section {
    border-top: 1px solid lightgray;
}

.members-bg {
    background-image: url('../src/assets/members-bg.jpg');
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(3px);
}

.rules-bg {
    background-image: url('../src/assets/rules-bg.jpg');
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(3px);
}

.announcements-bg {
    background-image: url('../src/assets/announcement-bg.jpg');
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(3px);
}